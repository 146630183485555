<template>
  <div class="main-content-box">
    <div class="banner-box">
      <bannerLeftEl/>
      <bannerCenterEl/>
      <bannerRightEl/>
    </div>
    <configZone1El/>
  </div>
</template>

<script>
import bannerLeftEl from "@/views/page/racenormal/banner_left";
import bannerCenterEl from "@/views/page/racenormal/banner_center";
import bannerRightEl from "@/views/page/racenormal/banner_right";
import configZone1El from "@/views/page/racenormal/config_zone_1";

export default {
  name: "page_race_normal",
  components:{
    bannerLeftEl,
    bannerCenterEl,
    bannerRightEl,
    configZone1El,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .banner-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
